// copied from element-plus/es/locale/lang/<locale>.mjs due to the fact that the locale files are not exported from the package
// causes issues with the build process when using the package
// HINT: might need to update this when updating element-plus

import de from "./de"
import en from "./en"
import hu from "./hu"
import es from "./es"

export const ElementPlusLocales = {
  de,
  en,
  hu,
  es,
}
