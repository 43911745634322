var de = {
  name: "de",
  el: {
    breadcrumb: {
      label: "Breadcrumb",
    },
    colorpicker: {
      confirm: "OK",
      clear: "Leeren",
    },
    datepicker: {
      now: "Jetzt",
      today: "Heute",
      cancel: "Abbrechen",
      clear: "Leeren",
      confirm: "OK",
      selectDate: "Datum w\xE4hlen",
      selectTime: "Uhrzeit w\xE4hlen",
      startDate: "Startdatum",
      startTime: "Startzeit",
      endDate: "Enddatum",
      endTime: "Endzeit",
      prevYear: "Letztes Jahr",
      nextYear: "N\xE4chtes Jahr",
      prevMonth: "Letzter Monat",
      nextMonth: "N\xE4chster Monat",
      day: "Tag",
      week: "Woche",
      month: "Monat",
      year: "",
      month1: "Januar",
      month2: "Februar",
      month3: "M\xE4rz",
      month4: "April",
      month5: "Mai",
      month6: "Juni",
      month7: "Juli",
      month8: "August",
      month9: "September",
      month10: "Oktober",
      month11: "November",
      month12: "Dezember",
      weeks: {
        sun: "So",
        mon: "Mo",
        tue: "Di",
        wed: "Mi",
        thu: "Do",
        fri: "Fr",
        sat: "Sa",
      },
      months: {
        jan: "Jan",
        feb: "Feb",
        mar: "M\xE4r",
        apr: "Apr",
        may: "Mai",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Okt",
        nov: "Nov",
        dec: "Dez",
      },
    },
    select: {
      loading: "L\xE4dt.",
      noMatch: "Nichts gefunden.",
      noData: "Keine Daten",
      placeholder: "Daten w\xE4hlen",
    },
    cascader: {
      noMatch: "Nichts gefunden.",
      loading: "L\xE4dt.",
      placeholder: "Daten w\xE4hlen",
      noData: "Keine Daten",
    },
    pagination: {
      goto: "Gehe zu",
      pagesize: " pro Seite",
      total: "Gesamt {total}",
      pageClassifier: "",
      page: "Page",
      prev: "Go to previous page",
      next: "Go to next page",
      currentPage: "page {pager}",
      prevPages: "Previous {pager} pages",
      nextPages: "Next {pager} pages",
    },
    messagebox: {
      confirm: "OK",
      cancel: "Abbrechen",
      error: "Fehler",
    },
    upload: {
      deleteTip: "Klicke l\xF6schen zum entfernen",
      delete: "L\xF6schen",
      preview: "Vorschau",
      continue: "Fortsetzen",
    },
    table: {
      emptyText: "Keine Daten",
      confirmFilter: "Anwenden",
      resetFilter: "Zur\xFCcksetzen",
      clearFilter: "Alles ",
      sumText: "Summe",
    },
    tour: {
      next: "Weiter",
      previous: "Zur\xFCck",
      finish: "Fertig",
    },
    tree: {
      emptyText: "Keine Eintr\xE4ge",
    },
    transfer: {
      noMatch: "Nichts gefunden.",
      noData: "Keine Eintr\xE4ge",
      titles: ["Liste 1", "Liste 2"],
      filterPlaceholder: "Eintr\xE4ge filtern",
      noCheckedFormat: "{total} Eintr\xE4ge",
      hasCheckedFormat: "{checked}/{total} ausgew\xE4hlt",
    },
    image: {
      error: "FEHLGESCHLAGEN",
    },
    pageHeader: {
      title: "Zur\xFCck",
    },
    popconfirm: {
      confirmButtonText: "Ja",
      cancelButtonText: "Nein",
    },
    carousel: {
      leftArrow: "Carousel arrow left",
      rightArrow: "Carousel arrow right",
      indicator: "Carousel switch to index {index}",
    },
  },
}

export { de as default }
